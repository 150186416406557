<template>
    <div class="grid">
        <Divider align="center">
            <span class="p-tag-outline">Observações</span>
        </Divider>
        <template
            v-if="
                agendamentoLocal.status.id === enumStatusAgendamento.PENDENCIA_CLIENTE ||
                agendamentoLocal.status.id === enumStatusAgendamento.CANCELADO
            "
        >
            <div class="col-12 md:col-6">
                <div class="field">
                    <label for="motivo">Motivo</label>
                    <Textarea id="motivo" class="w-30rem" type="text" v-model="agendamentoStatus.descricao" :disabled="isDisabled || appointmentCompleted" />
                </div>
            </div>
        </template>
        <template v-if="!agendamentoLocal.tipoExame || agendamentoLocal.tipoExame.id != this.enumTipoExame.CONSULTA">
            <div class="col-12 md:col-6">
                <label class="margin-bottom: 20px;" for="observacao">Observação</label>
                <div class="field">
                    <Textarea id="observacao" class="w-30rem" type="text" autoResize rows="3" v-model="agendamentoLocal.observacao" :disabled="true" />
                </div>
            </div>
        </template>
        <div class="col-12 md:col-6">
            <label for="particularidade">Particularidades</label>
            <div class="field">
                <Textarea id="particularidade" type="text" class="w-30rem" autoResize rows="3" v-model="agendamentoLocal.cliente.particularidades" :disabled="true" />
            </div>
        </div>
        <template v-if="agendamentoLocal.status.id === enumStatusAgendamento.PENDENCIA_RESOLVIDA && agendamentoStatus.descricao">
            <div class="col-12 md:col-6">
                <div class="field">
                    <label for="motivo">Descrição da resolução da pendencia</label>
                    <Textarea
                        id="motivo"
                        type="text"
                        class="w-30rem"
                        autoResize
                        rows="3"
                        v-model="agendamentoStatus.descricao"
                        :disabled="isDisabled || appointmentCompleted"
                    />
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import StatusAgendamento from '../../../../../enums/StatusAgendamento';
import EnumTipoExame from '../../../../../enums/TipoExame';
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        },
        isDisabled: {
            type: Boolean,
            required: true
        }
    },
    model: {
        prop: 'agendamento',
        event: 'changeAgendamento'
    },
    computed: {
        agendamentoLocal: {
            get: function () {
                return this.agendamento || {};
            },
            set: function (value) {
                this.$emit('changeAgendamento', value);
            }
        }
    },
    data() {
        return {
            agendamentoStatus: null,
            enumStatusAgendamento: StatusAgendamento,
            enumTipoExame: EnumTipoExame
        };
    }
};
</script>
