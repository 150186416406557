<template>
    <div>
        <Divider align="center">
            <span class="p-tag-outline">Dados do Funcionário</span>
        </Divider>
        <div class="grid col-12 pb-0">
            <div class="col-6 pb-0 pt-0">
                <div class="flex mb-3">
                    <label for="typeExamination">Colaborador: </label>
                    <p class="ml-2 text-400">
                        {{ agendamentoFuncionario?.nomeFuncionario ? agendamentoFuncionario?.nomeFuncionario : 'Não Informado' }}
                        {{ agendamentoFuncionario?.funcionario?.codigoSoc ? ' - ' + agendamentoFuncionario?.funcionario?.codigoSoc : '' }}
                    </p>
                </div>
                <div class="flex mb-3">
                    <label for="typeExamination">CPF/RG: </label>
                    <div class="text-400 ml-2">
                        {{ agendamentoFuncionario?.funcionario?.cpf ? agendamentoFuncionario?.funcionario?.cpf : 'Não Informado' }} /
                        {{ agendamentoFuncionario?.funcionario?.rg ? agendamentoFuncionario?.funcionario?.rg : 'Não Informado' }}
                    </div>
                </div>
                <div class="flex mb-3">
                    <label for="typeExamination">Data de Nascimento/Data Admissão: </label>
                    <div class="text-400 ml-2">
                        {{
                            agendamentoFuncionario?.funcionario?.dataNascimento
                                ? $filters.formatDateOnly(agendamentoFuncionario?.funcionario?.dataNascimento)
                                : 'Não Informado'
                        }}
                        -
                        {{
                            agendamentoFuncionario?.funcionario?.admissionDate
                                ? $filters.formatDateOnly(agendamentoFuncionario?.funcionario?.admissionDate)
                                : 'Não Informado'
                        }}
                    </div>
                </div>
                <div class="flex mb-3">
                    <label for="typeExamination">WhatsApp: </label>
                    <InputMask
                        class="ml-2 p-0"
                        style="height: 19px; width: 95px !important; border: none"
                        id="numberWhatsapp"
                        v-model="agendamentoFuncionario.telefoneFuncionario"
                        mask="(99) 99999-9999"
                        unmask
                        placeholder="Digite aqui"
                        :disabled="isDisabled"
                    />
                </div>
            </div>
            <div class="col-6 pb-0 pt-0">
                <div class="flex mb-3">
                    <label for="typeExamination">Unidade: </label>
                    <div class="text-400 ml-2">
                        {{ agendamentoFuncionario?.unidade?.name ?? 'Não informado' }}
                    </div>
                </div>
                <div class="flex mb-3">
                    <label for="typeExamination">Setor: </label>
                    <div class="text-400 ml-2">
                        {{ agendamentoFuncionario?.setor?.name ?? 'Não informado' }}
                    </div>
                </div>
                <div class="flex mb-3">
                    <label for="typeExamination">Cargo: </label>
                    <div class="text-400 ml-2">
                        {{ agendamentoFuncionario?.cargo?.name ?? 'Não informado' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AreaMedicaService from '../../../../../services/AreaMedicaService';
export default {
    props: {
        idAgendamento: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            agendamentoFuncionario: {},
            isDisabled: false
        };
    },
    mounted() {
        this.isDisabled = true;
        this.$serviceAgendamentoFuncionario = new AreaMedicaService('/agendamento_funcionario/agendamento');
        this.load();
    },
    methods: {
        async load() {
            const { data } = await this.$serviceAgendamentoFuncionario.findById(this.idAgendamento);
            this.agendamentoFuncionario = data;
        }
    }
};
</script>
