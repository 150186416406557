<template>
    <Menu id="menu_agendamento" ref="menuAgendamento" :model="menuItemsAgendamento" :popup="true" />
    <DialogPainelMedico v-if="openDialogMedico" v-model:visible="openDialogMedico" :idAgendamento="agendamento.id" />
    <DialogReagendar
        v-if="openReagendarDialog"
        v-model:visible="openReagendarDialog"
        :agendamento="agendamento"
        @onClose="openReagendarDialog = false"
    />
    <AppConfirmDialog
        v-if="openReenvioDialog"
        v-model:visible="openReenvioDialog"
        :loading="loadingKit"
        title="Deseja reenviar o kit desse Agendamento para o prestador e solicitante?"
        @onConfirm="reenviarKit"
        @onClose="openReenvioDialog = false"
    >
    </AppConfirmDialog>
    <DialogReprocessarAgendamento
        v-if="openReprocessarDialog"
        v-model:visible="openReprocessarDialog"
        :agendamento="agendamento"
        @onClose="openReprocessarDialog = false"
    />
    <DialogAddPendencias
        v-if="openPendenciasDialog"
        v-model:visible="openPendenciasDialog"
        :agendamento="agendamento"
        @onClose="openPendenciasDialog = false"
    />
    <DialogDesistenciaVaga
        v-if="openDesistenciaDialog"
        v-model:visible="openDesistenciaDialog"
        :agendamento="agendamento"
        @onClose="openDesistenciaDialog = false"
    />
    <FaltaConsultaDialog
        v-if="openFaltaConsultaDialog"
        v-model:visible="openFaltaConsultaDialog"
        :agendamento="agendamento"
        @onClose="openFaltaConsultaDialog = false"
    />
    <DialogCancelarAgendamento
        v-if="openCancelarDialog"
        v-model:visible="openCancelarDialog"
        :agendamento="agendamento"
        @onClose="openCancelarDialog = false"
    />
    <DialogDepositoAntecipado
        v-if="openDepositoAntecipadoDialog"
        v-model:visible="openDepositoAntecipadoDialog"
        :idAgendamento="agendamento.id"
        @onClose="onClickCloseDepositoAntecipado"
        @onConfirm="onClickConfirmDepositoAntecipado"
    />
    <AppAnexoDialog
        v-if="openAnexoDialog"
        v-model:visible="openAnexoDialog"
        title="Anexos para o Agendamento"
        tipo="Outro"
        origem="AGENDAMENTO"
        origemId="agendamento.id"
        @onClickCloseAnexo="openAnexoDialog = false"
        :modal="false"
    >
    </AppAnexoDialog>
    <TimelinePainelAgendamentoDialog
        v-if="openTimelineDialog"
        v-model:visible="openTimelineDialog"
        :agendamento="agendamento"
        @onClose="openTimelineDialog = false"
    >
    </TimelinePainelAgendamentoDialog>
    <ComentariosAgendamentoDialog
        v-if="openComentariosDialog"
        v-model:visible="openComentariosDialog"
        :agendamento="agendamento"
        @onClose="openComentariosDialog = false"
    >
    </ComentariosAgendamentoDialog>
    <AppProcessoDialog
        v-if="openProcessosDialog"
        v-model:visible="openProcessosDialog"
        title="Processos para o Agendamento"
        origem="AGENDAMENTO"
        :origemId="agendamento.id"
        processarPath="/agendamentos/processar-processo/"
    >
    </AppProcessoDialog>
</template>
<script>
import { getClientAreaMedica, getClientBase } from '../../../services/http';
import MixinAgendamentoStatusValidator from '../mixins/MixinAgendamentoStatusValidator';
import DialogPainelMedico from './DialogPainelMedico.vue';
import DialogReagendar from './DialogReagendar.vue';
import AppConfirmDialog from '../../../components/AppConfirmDialog.vue';
import DialogReprocessarAgendamento from './DialogReprocessarAgendamento.vue';
import DialogAddPendencias from './DialogAddPendencias.vue';
import DialogDesistenciaVaga from './DialogDesistenciaVaga.vue';
import SituacaoAgendamento from '../../../enums/SituacaoAgendamento';
import FaltaConsultaDialog from '../../gestao-medica/components/FaltaConsultaDialog.vue';
import DialogCancelarAgendamento from './DialogCancelarAgendamento.vue';
import TimelinePainelAgendamentoDialog from '../../gestao-medica/components/timeline/TimelineAgendamentoDialog.vue';
import ComentariosAgendamentoDialog from '../../painel-agendamento/components/ComentariosAgendamentoDialog.vue';
import AreaMedicaService from '../../../services/AreaMedicaService';
import DialogDepositoAntecipado from '../../painel-agendamento/components/DialogDepositoAntecipado.vue';
import BaseService from '../../../services/BaseService';
import StatusAgendamento from '../../../enums/StatusAgendamento';
import { showError, showSuccess } from '../../../utils/Toast';
export default {
    components: {
        DialogPainelMedico,
        DialogReagendar,
        AppConfirmDialog,
        DialogReprocessarAgendamento,
        DialogAddPendencias,
        DialogDesistenciaVaga,
        FaltaConsultaDialog,
        DialogDepositoAntecipado,
        TimelinePainelAgendamentoDialog,
        DialogCancelarAgendamento,
        ComentariosAgendamentoDialog
    },
    props: {
        agendamento: {
            type: Object,
            required: true
        }
    },
    mixins: [MixinAgendamentoStatusValidator],
    data() {
        return {
            openDialogMedico: false,
            openReagendarDialog: false,
            openReenvioDialog: false,
            enumStatusAgendamento: StatusAgendamento,
            openDesistenciaDialog: false,
            openPendenciasDialog: false,
            openFaltaConsultaDialog: false,
            openReprocessarDialog: false,
            loadingDepositoAntecipado: false,
            openCancelarDialog: false,
            agendamentoCompleto: null,
            openTimelineDialog: false,
            openAnexoDialog: false,
            openComentariosDialog: false,
            openProcessosDialog: false,
            openDepositoAntecipadoDialog: false,
            menuItemsAgendamento: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    disabled: () =>
                        this.isStatusDesistenciaDeVaga(this.agendamento) ||
                        this.isStatusCancelado(this.agendamento) ||
                        this.isStatusConcluido(this.agendamento) ||
                        this.isStatusAgendado(this.agendamento) ||
                        this.isStatusAguardandoAplicacaoRisco(this.agendamento) ||
                        this.isStatusProcessandoAgendamento(this.agendamento) ||
                        !this.$checkPermission('gestamedica_painel_agendamento:alterar') ||
                        this.isStatusNaoConfirmado(this.agendamento),
                    command: () => {
                        this.openDialogMedico = true;
                    }
                },
                {
                    label: 'Reagendar',
                    icon: 'pi pi-book',
                    disabled: () =>
                        this.isStatusEmAberto(this.agendamento) ||
                        this.isStatusDesistenciaDeVaga(this.agendamento) ||
                        this.isStatusCancelado(this.agendamento) ||
                        this.isStatusConcluido(this.agendamento) ||
                        this.isStatusEmAndamento(this.agendamento) ||
                        this.isStatusPendenciaResolvida(this.agendamento) ||
                        this.isStatusPendente(this.agendamento) ||
                        this.isStatusAguardandoAplicacaoRisco(this.agendamento) ||
                        this.isStatusPendenciaCliente(this.agendamento) ||
                        this.isStatusAguardandoConfColaborador(this.agendamento) ||
                        this.isStatusProcessandoAgendamento(this.agendamento) ||
                        !this.$checkPermission('gestamedica_painel_agendamento:reagendar'),
                    command: () => {
                        this.openReagendarDialog = true;
                    }
                },
                {
                    label: 'Reenviar Kit',
                    icon: 'pi pi-sync',
                    disabled: () =>
                        this.isStatusNaoConfirmado(this.agendamento) ||
                        this.isStatusEmAberto(this.agendamento) ||
                        this.isStatusDesistenciaDeVaga(this.agendamento) ||
                        this.isStatusCancelado(this.agendamento) ||
                        this.isStatusConcluido(this.agendamento) ||
                        this.isStatusEmAndamento(this.agendamento) ||
                        this.isStatusPendenciaResolvida(this.agendamento) ||
                        this.isStatusPendente(this.agendamento) ||
                        this.isStatusAguardandoAplicacaoRisco(this.agendamento) ||
                        this.isStatusPendenciaCliente(this.agendamento) ||
                        this.isStatusAguardandoConfColaborador(this.agendamento) ||
                        this.isStatusProcessandoAgendamento(this.agendamento) ||
                        !this.$checkPermission('gestamedica_painel_agendamento:reenviarkit'),
                    command: () => {
                        this.openReenvioDialog = true;
                    }
                },
                {
                    label: 'Reprocessar',
                    icon: 'pi pi-sync',
                    visible: () => this.isStatusKitPendente(this.agendamento) || this.isStatusNaoConfirmado(this.agendamento),
                    disabled: () => this.isStatusNaoConfirmado(this.agendamento),
                    command: () => {
                        this.openReprocessarDialog = true;
                    }
                },
                {
                    label: 'Adicionar Pendência',
                    icon: 'pi pi-plus',
                    disabled: () =>
                        this.isStatusNaoConfirmado(this.agendamento) ||
                        this.isStatusEmAberto(this.agendamento) ||
                        this.isStatusDesistenciaDeVaga(this.agendamento) ||
                        this.isStatusCancelado(this.agendamento) ||
                        this.isStatusConcluido(this.agendamento) ||
                        this.isStatusAgendado(this.agendamento) ||
                        this.isStatusPendenciaCliente(this.agendamento) ||
                        this.isStatusAguardandoConfColaborador(this.agendamento) ||
                        this.isStatusProcessandoAgendamento(this.agendamento) ||
                        !this.$checkPermission('gestamedica_painel_agendamento:pendencia'),
                    command: async () => {
                        this.openPendenciasDialog = true;
                    }
                },
                {
                    label: 'Desistência de Vaga',
                    icon: 'pi pi-thumbs-down',
                    disabled: () =>
                        !this.isTipoAgendamentoAdmissional(this.agendamento) || !this.$checkPermission('gestamedica_painel_agendamento:desistencia'),
                    command: () => {
                        this.openDesistenciaDialog = true;
                    }
                },
                {
                    label: 'Falta consulta',
                    icon: 'pi pi-thumbs-down',
                    disabled: () =>
                        this.isStatusNaoConfirmado(this.agendamento) ||
                        this.isStatusDesistenciaDeVaga(this.agendamento) ||
                        this.isStatusCancelado(this.agendamento) ||
                        this.isStatusEmAberto(this.agendamento) ||
                        this.isStatusProcessandoAgendamento(this.agendamento) ||
                        !this.$checkPermission('gestamedica_painel_agendamento:desistencia'),
                    command: () => {
                        this.openFaltaConsultaDialog = true;
                    }
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    disabled: () =>
                        this.isStatusNaoConfirmado(this.agendamento) ||
                        this.isStatusDesistenciaDeVaga(this.agendamento) ||
                        this.isStatusCancelado(this.agendamento) ||
                        this.isStatusConcluido(this.agendamento) ||
                        !this.$checkPermission('gestamedica_painel_agendamento:cancelar'),
                    command: () => {
                        this.openCancelarDialog = true;
                    }
                },
                {
                    label: 'Depósito Antecipado',
                    icon: 'pi pi-dollar',
                    disabled: () => this.isStatusNaoConfirmado(this.agendamento) || !this.isStatusDepositoAntecipado(this.agendamento),
                    command: () => (this.openDepositoAntecipadoDialog = true)
                },
                {
                    label: 'Anexos',
                    icon: 'pi pi-paperclip',
                    disabled: () => !this.$checkPermission('gestamedica_painel_agendamento:anexos'),
                    command: () => (this.openAnexoDialog = true)
                },
                {
                    label: 'Timeline',
                    icon: 'pi pi-sort-amount-down-alt',
                    disabled: () => this.isStatusNaoConfirmado(this.agendamento) || !this.$checkPermission('gestamedica_painel_agendamento:timeline'),
                    command: () => (this.openTimelineDialog = true)
                },
                {
                    label: 'Comentários',
                    icon: 'pi pi-comment',
                    command: () => (this.openComentariosDialog = true)
                },
                {
                    label: 'Processos',
                    icon: 'pi pi-server',
                    visible: false,
                    command: () => (this.openProcessosDialog = true)
                },
                {
                    label: 'Gerar S-2220',
                    icon: 'pi pi-cloud',
                    disabled: () => !this.isStatusConcluido(this.agendamento) || !this.$checkPermission('esocial_eventos:criar'),
                    command: () => this.onClickMenuGerarS2220(this.agendamento)
                }
            ]
        };
    },
    mounted() {
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
        this.$serviceAgendamento = new BaseService('/agendamentos');
        this.load();
    },
    methods: {
        toggle($event) {
            this.$refs.menuAgendamento.toggle($event);
        },
        async load() {
            try {
                const { data } = await this.$serviceAgendamento.findById(this.agendamento.id);
                this.agendamentoCompleto = data;
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: err.response?.data?.message || err?.message, life: 3000 });
            }
        },
        async reenviarKit() {
            try {
                this.loadingKit = true;
                const path = `agendamento/enviar-kit/${this.agendamento.id}`;
                await getClientAreaMedica().post(path);
                this.loadingKit = false;
                this.openReenvioDialog = false;
                this.$toast.add({ severity: 'success', summary: 'O Kit de Agendamento foi reenviado com sucesso!', life: 3000 });
            } catch (error) {
                this.loadingKit = false;
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error?.message, life: 3000 });
            }
        },
        async onClickMenuGerarS2220(record) {
            try {
                const path = `esocial/eventos/S2220/agendamentos/${record.id}`;
                await getClientBase().post(path);
                showSuccess(this.$toast, 'O evento foi gerado');
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async onClickConfirmDepositoAntecipado() {
            try {
                this.loadingDepositoAntecipado = true;
                await this.completeAppointment(this.agendamentoCompleto);
                this.openDepositoAntecipadoDialog = false;
                this.loadingDepositoAntecipado = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao confirmar o agendamento!', detail: error.message, life: 3000 });
                this.loadingDepositoAntecipado = false;
            }
        },
        async completeAppointment(record) {
            const campoNaoPreenchido = this.getCampoNaoPreenchido(record);

            if (campoNaoPreenchido) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar informações!', detail: campoNaoPreenchido, life: 3000 });
                return;
            }

            const novoStatus = this.getIdStatusModificacao(record);
            if (novoStatus) {
                record.status = novoStatus;
            }

            try {
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Agendado.',
                    agendamento: record,
                    status: record.status
                };

                await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                if (record.status.id === this.enumStatusAgendamento.AGENDADO) {
                    this.appointmentCompleted = true;
                }
                this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', life: 3000 });
            } catch (err) {
                const innerMessage = err?.response?.data?.details?.response?.message;
                const messages = Array.isArray(innerMessage) ? innerMessage.join() : innerMessage;
                const message = err?.response?.data?.message;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: `${message} - ${messages}`, life: 3000 });
            }
        },
        getCampoNaoPreenchido(record) {
            const faltaSituacao = !this.getIdStatusModificacao(record);
            if (faltaSituacao) return 'Situação não informada';
            const dataAgendamentoNaoInformada = !!this.agendamentoCompleto.procedimentos.find((p) => !p.dataAgendamento);
            if (dataAgendamentoNaoInformada) return 'Data de agendamento não informada nos exames';

            const prestadorNaoInformado = !!this.agendamentoCompleto.procedimentos.find((p) => !p.prestador);
            if (prestadorNaoInformado) return 'Prestador não informado nos exames';

            return false;
        },
        getIdStatusModificacao(record) {
            let status;
            if (!record) return;

            switch (record.situacao) {
                case SituacaoAgendamento.ENVIAR_CONFIRMACAO:
                case SituacaoAgendamento.CONFIRMAR_MANUALMENTE:
                case SituacaoAgendamento.AGUARDAR_CONFIRMACAO:
                    status = { id: this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR };
                    break;
                case SituacaoAgendamento.CONFIRMAR:
                    status = { id: this.enumStatusAgendamento.AGENDADO };
                    break;
            }

            return status;
        }
    }
};
</script>
