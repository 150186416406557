<template>
    <TabView>
        <TabPanel header="Dados do agendamento" :disabled="!disabled"> 
            <TabPanelDadosAgendamento v-bind:agendamento="agendamento" @changeDisabled="desabilitaTab"/>
        </TabPanel> 
        <TabPanel header="Dados do ASO" :disabled="!disabled">
            <TabPanelDadosAso v-bind:agendamento="agendamento" @changeDisabled="desabilitaTab"/>
        </TabPanel>
        <TabPanel header="Dados do solicitante" :disabled="!disabled"> 
            <TabPanelDadosSolicitante v-bind:agendamento="agendamento" @changeDisabled="desabilitaTab"/> 
        </TabPanel>
        <TabPanel header="Ficha clínica"> 
            <TabPanelFichaClinica v-bind:agendamento="agendamento"/>
        </TabPanel> 
        <TabPanel header="Resultado de exames"> 
            <TabPanelResultadoExame v-bind:agendamento="agendamento"/>
        </TabPanel> 
        <TabPanel header="Telemedicina"> 
            <TabPanelTelemedicina v-bind:agendamento="agendamento"/>
        </TabPanel>
    </TabView>
</template>
<script>
import TabPanelDadosAgendamento from './tab-panel-dados-agendamento/TabPanelDadosAgendamento.vue';
import TabPanelDadosAso from './tab-panel-dados-aso/TabPanelDadosAso.vue';
import TabPanelDadosSolicitante from './tab-panel-dados-solicitante/TabPanelDadosSolicitante.vue';
import TabPanelFichaClinica from './TabPanelFichaClinica.vue';
import TabPanelResultadoExame from './TabPanelResultadoExame.vue'
import TabPanelTelemedicina from './TabPanelTelemedicina.vue' 
export default {
    components: {
        TabPanelDadosAgendamento,
        TabPanelDadosAso,
        TabPanelDadosSolicitante,
        TabPanelFichaClinica,
        TabPanelResultadoExame,
        TabPanelTelemedicina 
    },
    props: {
        agendamento: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            disabled: true
        }
    },
    methods: {
        desabilitaTab() {
            this.disabled = !this.disabled;
        }
    }
};
</script>
