<template>
    <Dialog class="p-fluid" :style="{width: '450px'}"
        header="Reprocessar Kit" :draggable="false" :closable="false" :modal="true">
        <div>Você deseja reprocessar o Kit?</div>
        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-outlined p-button-primary"
                @click="closeDialog" />
            <Button label="Reprocessar" :loading="loadReprocessar" icon="pi pi-sync"
                @click="reprocessarKit" />
        </template>
    </Dialog>
</template>
<script>
import { getClientAreaMedica } from '../../../services/http';
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loadReprocessar: false
        }
    },
    emits: ['onClose'],
    methods: {
        async reprocessarKit(){
            this.loadReprocessar = true;
            try {
                const path = `agendamentos/reprocessar/${this.agendamento.id}`;
                await getClientAreaMedica().patch(path);
                this.$toast.add({ severity: 'success', summary: 'O Kit de Agendamento foi reprocessado com sucesso!', life: 3000 });
                this.$emit('onClose');
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
            }
            this.loadReprocessar = false;
        },
        closeDialog() {
            this.$emit('onClose');
        }
    }
}
</script>
