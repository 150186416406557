import StatusAgendamento from '../../../enums/StatusAgendamento';
import AreaMedicaService from '../../../services/AreaMedicaService';
import SituacaoAgendamento from '../../../enums/SituacaoAgendamento';
import TipoExame from '../../../enums/TipoExame';
const AgendamentoStatusValidator = {
    data() {
        return {
            enumStatusAgendamento: StatusAgendamento,
            enumSituacaoAgendamento: SituacaoAgendamento,
            enumTipoExame: TipoExame
        };
    },
    mounted() {
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
    },
    methods: {
        isStatusEmAberto(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.EM_ABERTO;
        },
        isStatusEmAndamento(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.EM_ANDAMENTO;
        },
        isStatusAguardandoConfColaborador(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR;
        },
        isStatusPendenciaCliente(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.PENDENCIA_CLIENTE;
        },
        isStatusAguardandoData(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.AGUARDANDO_DATA;
        },
        isStatusDiaDaConsulta(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.DIA_DA_CONSULTA;
        },
        isStatusDesistenciaDeVaga(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.DESISTENCIA_DE_VAGA;
        },
        isStatusConcluido(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.CONCLUIDO;
        },
        isStatusCancelado(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.CANCELADO;
        },
        isStatusAgendado(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.AGENDADO;
        },
        isStatusConfirmacaoAgenda(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA;
        },
        isStatusAguardandoAplicacaoRisco(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.AGUARDANDO_APLICACAO_RISCO;
        },
        isStatusProcessandoAgendamento(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.PROCESSANDO_AGENDAMENTO;
        },
        isStatusPendenciaResolvida(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.PENDENCIA_RESOLVIDA;
        },
        isStatusKitPendente(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.KIT_PENDENTE;
        },
        isStatusPendente(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.PENDENCIA_PRESTADOR;
        },
        isStatusNaoConfirmado(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.NAO_CONFIRMADO;
        },
        isStatusDepositoAntecipado(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.DEPOSITO_ANTECIPADO;
        },
        isAgendamentoDepositoAntecipado(agendamento) {
            return agendamento.depositoAntecipado;
        },
        isStatusAguardandoConfComparecimento(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.AGUARDANDO_CONF_COMPARECIMENTO;
        },
        isStatusFaltaConsulta(agendamento) {
            return agendamento.status.id == this.enumStatusAgendamento.FALTA_CONSULTA;
        },
        isStatusAutoagendamento(agendamento) {
            return agendamento.status.id === this.enumStatusAgendamento.AUTOAGENDAMENTO;
        },
        isTipoAgendamentoAdmissional(agendamento) {
            return agendamento.tipoExame.id === this.enumTipoExame.ADMISSIONAL;
        }
    }
};
export default AgendamentoStatusValidator;
