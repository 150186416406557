<template>
    <FormDadosSolicitante v-if="agendamentoLocal" v-bind:agendamento="agendamentoLocal" :appointmentCompleted="appointmentCompleted" :isDisabled="isDisabled" />
    <div v-if="agendamentoLocal" class="grid">
        <div class="col-3">
            <Button
                v-if="agendamentoLocal && agendamentoLocal.id"
                :disabled="
                    this.isStatusEmAberto(agendamentoLocal) ||
                    this.isStatusConcluido(agendamentoLocal) ||
                    this.isStatusDesistenciaDeVaga(agendamentoLocal) ||
                    this.isStatusCancelado(agendamentoLocal) ||
                    this.isStatusAgendado(agendamentoLocal) ||
                    this.isStatusAguardandoAplicacaoRisco(agendamentoLocal) ||
                    this.isStatusProcessandoAgendamento(agendamentoLocal) ||
                    !this.$checkPermission('gestamedica_painel_agendamento:alterar')
                "
                label="Alterar dados"
                icon="pi pi-pencil"
                class="p-button-outlined w-20rem"
                @click="onClickAlterarDados"
            />
        </div>
        <div class="col-3">
            <Button
                :loading="loadingSalvarAgendamento"
                :disabled="
                    loadingAgendar ||
                    this.isStatusConcluido(agendamentoLocal) ||
                    loadingIniciarAgendamento ||
                    isDisabled ||
                    appointmentCompleted ||
                    agendamentoLocal.status.id === enumStatusAgendamento.CANCELADO
                "
                class="p-button-success mr-2 w-20rem"
                label="Salvar"
                icon="pi pi-save"
                @click="saveChanges()"
            />
        </div>
        <div class="col-3">
            <Button
                :loading="loadingAgendar"
                :disabled="
                    agendamentoLocal.situacao === enumSituacaoAgendamento.EXAMES_EM_DIA ||
                    this.isStatusConcluido(agendamentoLocal) ||
                    loadingSalvarAgendamento ||
                    loadingIniciarAgendamento ||
                    isDisabled ||
                    !appointmentStarted ||
                    appointmentCompleted ||
                    agendamentoLocal.status.id === enumStatusAgendamento.PENDENCIA_CLIENTE ||
                    agendamentoLocal.status.id === enumStatusAgendamento.DESISTENCIA_DE_VAGA ||
                    agendamentoLocal.status.id === enumStatusAgendamento.CANCELADO
                "
                label="Concluir"
                icon="pi pi-check"
                class="p-button-primery w-20rem"
                @click="completeAppointment(agendamentoLocal)"
            />
        </div>
        <div class="col-1">
            <Button
                icon="pi pi-whatsapp"
                class="p-button-success pb-3 pr-4 pl-4 pt-2 w-5rem"
                :disabled="
                    this.isStatusEmAberto(agendamentoLocal) ||
                    this.isStatusConcluido(agendamentoLocal) ||
                    this.isStatusDesistenciaDeVaga(agendamentoLocal) ||
                    this.isStatusCancelado(agendamentoLocal) ||
                    this.isStatusAgendado(agendamentoLocal) ||
                    this.isStatusAguardandoAplicacaoRisco(agendamentoLocal) ||
                    this.isStatusProcessandoAgendamento(agendamentoLocal)
                "
                @click="onClickConfirmEnvioWhats()"
                v-tooltip.top="'Enviar WhatsApp'"
            ></Button>
        </div>
    </div>
</template>
<script>
import FormDadosSolicitante from './components/FormDadosSolicitante.vue';
import MixinAgendamentoStatusValidator from '../../mixins/MixinAgendamentoStatusValidator';
import StatusAgendamento from '../../../../enums/StatusAgendamento';
import SituacaoAgendamento from '../../../../enums/SituacaoAgendamento';
import AreaMedicaService from '../../../../services/AreaMedicaService';
export default {
    components: {
        FormDadosSolicitante
    },
    props: {
        agendamento: {
            type: Object,
            required: true
        }
    },
    emits: ['changeDisabled'],
    mixins: [MixinAgendamentoStatusValidator],
    model: {
        prop: 'agendamento',
        event: 'changeAgendamento'
    },
    data() {
        return {
            agendamentoLocal: null,
            enumStatusAgendamento: StatusAgendamento,
            enumSituacaoAgendamento: SituacaoAgendamento,
            loadingSalvarAgendamento: false,
            loadingAgendar: false,
            corpoMensagemWhats: null,
            loadingIniciarAgendamento: false,
            isDisabled: true,
            appointmentCompleted: false,
            submitted: false,
            appointmentStarted: true,
            showEnvioWhatsDialog: false,
            agendamentoFuncionario: null
        };
    },
    mounted() {
        this.agendamentoLocal = this.agendamento;
        this.$serviceAgendamento = new AreaMedicaService('/agendamentos');
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
    },
    methods: {
        onClickAlterarDados() {
            this.isDisabled = false;
            this.$emit('changeDisabled');
        },
        async saveChanges() {
            try {
                const { data } = await this.$serviceAgendamento.save(this.agendamentoLocal);
                this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'Dados do agendamento salvos com sucesso.', life: 5000 });
                this.isDisabled = true;  
                this.$emit('changeDisabled');
                this.loadingSalvarAgendamento = false;
                this.agendamentoLocal = data;
            } catch (err) {
                this.loadingSalvarAgendamento = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err?.response?.data?.message, life: 3000 });
            }
            this.$emit('changeAgendamento', this.agendamentoLocal);
        },
        async completeAppointment(record) {
            await this.saveChanges();

            this.submitted = true;
            this.loadingAgendar = true;
            const campoNaoPreenchido = this.getCampoNaoPreenchido(record);

            if (campoNaoPreenchido) {
                this.loadingAgendar = false;
                this.isDisabled = false;
                this.appointmentCompleted = false;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar informações!', detail: campoNaoPreenchido, life: 3000 });

                return;
            }

            const novoStatus = this.getIdStatusModificacao(record);
            if (novoStatus) {
                record.status = novoStatus;
            }

            try {
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Agendado.',
                    agendamento: record,
                    status: record.status
                };

                const { data } = await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                if (record.status.id === this.enumStatusAgendamento.AGENDADO) {
                    this.appointmentCompleted = true;
                }

                this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', life: 3000 });
                this.loadingAgendar = false;
                this.agendamentoLocal.user = data.user;
                this.agendamentoLocal.status = data.agendamento.status;
                this.agendamentoLocal.depositoAntecipado = data.agendamento.depositoAntecipado;
                this.agendamentoLocal.msgErroProcessamento = data.agendamento.msgErroProcessamento;
                this.agendamentoLocal.erroProcessamento = data.agendamento.erroProcessamento;
                this.agendamentoLocal.etapaProcessamento = data.agendamento.etapaProcessamento;

                this.isDisabled = true;   
                this.$emit('changeDisabled');
            } catch (err) {
                this.loadingAgendar = false;
                this.isDisabled = false;
                this.appointmentCompleted = false;
                const innerMessage = err?.response?.data?.details?.response?.message;
                const messages = Array.isArray(innerMessage) ? innerMessage.join() : innerMessage;
                const message = err?.response?.data?.message;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: `${message} - ${messages}`, life: 3000 });
            }
        },
        getCampoNaoPreenchido(record) {
            const faltaSituacao = !this.getIdStatusModificacao(record);
            if (faltaSituacao) return 'Situação não informada';
            const dataAgendamentoNaoInformada = !!this.agendamentoLocal.agendamentoProcedimentos.find((p) => !p.dataAgendamento);
            if (dataAgendamentoNaoInformada) return 'Data de agendamento não informada nos exames';

            const prestadorNaoInformado = !!this.agendamentoLocal.agendamentoProcedimentos.find((p) => !p.prestador);
            if (prestadorNaoInformado) return 'Prestador não informado nos exames';

            return false;
        },
        getIdStatusModificacao(record) {
            let status;
            if (!record) return;

            switch (record.situacao) {
                case SituacaoAgendamento.ENVIAR_CONFIRMACAO:
                case SituacaoAgendamento.CONFIRMAR_MANUALMENTE:
                case SituacaoAgendamento.AGUARDAR_CONFIRMACAO:
                    status = { id: this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR };
                    break;
                case SituacaoAgendamento.CONFIRMAR:
                    status = { id: this.enumStatusAgendamento.AGENDADO };
                    break;
            }

            return status;
        },
        onClickConfirmEnvioWhats() {
            const dadosExameClinico = this.agendamentoLocal.procedimentos.find((data) => {
                if (data.procedimentos.clinical) {
                    return { data };
                }
            });
            if (!dadosExameClinico) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Agendamento não possui exame clínico',
                    detail: 'Informe os valores não informado na mensagem',
                    life: 10000
                });
            }

            const rua = dadosExameClinico?.prestador?.street ?? 'Não informado';
            const numero = dadosExameClinico?.prestador?.number ?? 'Não informado';
            const bairro = dadosExameClinico?.prestador?.neighborhood ?? 'Não informado';
            const cidade = dadosExameClinico?.prestador?.city?.cidade
                ? dadosExameClinico?.prestador?.city?.cidade + ' - ' + dadosExameClinico?.prestador?.city?.estado
                : 'Não informado';
            const complemento = dadosExameClinico?.prestador?.addressComplement
                ? 'Complemento: ' + dadosExameClinico.prestador.addressComplement
                : '';
            const cep = dadosExameClinico?.prestador?.zipCode ?? 'Não informado';

            const enderecoPrestadorClinico = `Rua: ${rua} Nº: ${numero}, Bairro: ${bairro} ${complemento}, Cidade: ${cidade}, CEP: ${cep}`;
            this.corpoMensagemWhats = `Olá *${
                this.agendamentoLocal?.funcionario?.name
            }*, tudo bem? Faço parte da equipe da ${this.agendamentoLocal?.company?.name}, e estou entrando em contato para informar sobre o agendamento do seu exame ${
                this.appointmentSelected?.tipoExame?.descricao
            } pela empresa *${this.appointmentSelected?.cliente?.tradeName}*. Seu exame está agendado para o dia *${
                dadosExameClinico?.dataAgendamento ? this.$filters.formatDate(dadosExameClinico?.dataAgendamento) : 'Não Informado'
            }* na clinica: *${
                dadosExameClinico?.prestador?.name ? dadosExameClinico.prestador.name : 'Não informado'
            }* Endereço: *${enderecoPrestadorClinico}*. O agendamento só será realizado, mediante a confirmação desta mensagem!`;
            this.showEnvioWhatsDialog = true;
        },
        onClickCloseEnvioWhatsDialog() {
            this.showEnvioWhatsDialog = false;
            this.corpoMensagemWhats = null;
        },
    }
};
</script>
