<template>
    <div>
        <AppLoadingWrapper v-if="loading" />
        <Timeline v-if="!loading && ninaLogs" :value="ninaLogs">
            <template #marker="slotProps" class="customized-timeline">
                <span class="custom-marker shadow-2" :style="{ backgroundColor: slotProps.item.hexColor }">
                    <i :class="slotProps.item.iconName"></i>
                </span>
            </template>
            <template #content="slotProps">
                <Card class="mb-5">
                    <template #title>
                        <div class="flex flex-row">
                            <div class="font-semibold text-xl">
                                {{ slotProps.item.title }}
                            </div>
                            <div class="ml-auto">
                                <small class="font-light text-base">
                                    <i class="pi pi-clock"></i>
                                    {{ slotProps.item.hour }}
                                </small>
                            </div>
                        </div>
                    </template>
                    <template #content v-if="slotProps.item.type != 'CREATED'">
                        <div v-html="slotProps?.item?.desc">
                        </div>
                    </template>
                </Card>
            </template>
        </Timeline>
        <div v-if="!ninaLogs">
            <div class="h-30rem w-full flex justify-content-center align-items-center">
                <div class="flex flex-column justify-content-center align-items-center text-500">
                    <div class="mb-4">
                        <i class="pi pi-search" style="font-size: 5rem"></i>
                    </div>
                    <div>
                        Não foi encontrado nenhum registro de agendamento
                    </div>
                    <div>
                        com a Nina vinculada a esse agendamento
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseService from '../../../../../services/BaseService';
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            active: 0,
            ninaLogs: [],
            loading: false,
        };
    },
    created() {
        this.load();
    },
    methods: {
        async load() {
            this.loading = true;
            const service = new BaseService(`/timeline/whatsapp/${this.agendamento.id}`);
            const { data } = await service.findAll({});
            this.ninaLogs = data;
            this.loading = false;
        }
    }
}
</script>
<style lang="scss" scoped>
:deep(.p-timeline-event-opposite) {
    display: none;
}

.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
</style>