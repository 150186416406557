<template>
    <Dialog class="p-fluid" :style="{ width: '450px' }" header="Confirmar"
        :draggable="false" :closable="false" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="agendamentoLocal">Ao confirmar a desistência o agendamento será
                cancelado. Deseja confirmar esta ação?</span>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
            <Button label="Confirmar" icon="pi pi-check" class="p-button-outlined p-button-danger"
                @click="confirmGiveUp" :loading="loadingDesistencia" />
        </template>
    </Dialog>
</template>
<script>
import AreaMedicaService from '../../../services/AreaMedicaService';
import StatusAgendamento from '../../../enums/StatusAgendamento';
export default {
    props: {
        agendamento:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
            agendamentoLocal: null,
            loadingDesistencia: false,
            enumStatusAgendamento: StatusAgendamento
        }
    },
    emits: ['onClose'],
    mounted() {
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
        this.agendamentoLocal = this.agendamento;
    },
    methods: {
        async confirmGiveUp() {
            if (this.agendamentoLocal.status.id === this.enumStatusAgendamento.DESISTENCIA_DE_VAGA) {
                this.closeDialog();
                this.$toast.add({ severity: 'warn', summary: 'Informação', detail: 'Agendamento já está cancelado', life: 3000 });
                return;
            }

            this.agendamentoLocal.status = { id: this.enumStatusAgendamento.DESISTENCIA_DE_VAGA };
            try {
                this.loadingDesistencia = true;
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Desistência.',
                    agendamento: this.agendamentoLocal,
                    status: this.agendamentoLocal.status
                }

                await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', detail: 'Agendamento alterado como desistência.', life: 5000 });
                this.loadingDesistencia = false;
                this.closeDialog();
            } catch (err) {
                this.loadingDesistencia = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err?.response?.data?.message, life: 3000 });
            }
        },
        closeDialog() {
            this.$emit('onClose');
        }
    }
}
</script>
