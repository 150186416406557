<template>
    <div>
        <Divider align="center">
            <span class="p-tag-outline">Exames</span>
        </Divider>
        <Message v-if="hasExameEmDia.length" :closable="false" severity="info" class="col-12 p-0 m-0">
            <div class="flex-column">
                <p class="p-0 m-0">Este funcionário está com os Exames em dia!</p>
                <small>Exames a serem cobrados:{{ nomesExamesEmDia }}.</small>
            </div>
        </Message>
        <Message v-if="agendamentoLocal.asoEmDia" :closable="false" severity="info" class="col-12 p-0 m-0">
            <div class="flex-column">
                <p class="p-0 m-0">Este funcionário está com o ASO em dia, mas confirmou a realização do exame.</p>
            </div>
        </Message>
        <div class="col-12">
            <Button
                :disabled="isDisabled || appointmentCompleted"
                icon="pi pi-plus"
                class="p-button-outlined mb-2"
                @click="openDialogNovoProcedimento"
                v-tooltip="'Novo procedimento'"
            />
            <DataTable class="p-datatable-sm" :value="agendamentoLocal.procedimentos" responsiveLayout="scroll" editMode="cell">
                <Column field="procedimentos.name" header="Procedimento"></Column>
                <Column field="agendamento.prestador.name" header="Prestador" style="min-width: 8rem">
                    <template #body="slotProps">
                        <DropdownPrestadorCliente
                            v-model="slotProps.data.prestador"
                            :disabled="isDisabled || appointmentCompleted"
                            :idCliente="agendamentoLocal.cliente.id"
                            :idCidade="agendamentoLocal.cidade.id"
                            :filtrosExtras="{ atendeTodasCidades: true }"
                            :autoLoad="true"
                            :class="{ 'p-invalid': submitted && !slotProps.data.prestador }"
                        />
                        <small class="p-error" v-if="submitted && !slotProps.data.prestador">Campo obrigatório.</small>
                    </template>
                </Column>
                <Column field="dataAgendamento" header="Data Agendamento" style="min-width: 8rem">
                    <template #body="{ data }">
                        <AppInputCalendarioSingle
                            ref="inputCalendario"
                            id="onlyDataAgendamento"
                            v-model="data.dataAgendamento"
                            :required="true"
                            :submitted="submitted"
                            :disabled="isDisabled || appointmentCompleted"
                            :horarioAgendamento="data.onlyHoraAgendamento"
                            :idCidade="agendamentoLocal.cidade?.id"
                            :idPrestador="data.prestador?.id"
                        />
                    </template>
                </Column>
                <Column header="Hora Agendamento" style="min-width: 8rem">
                    <template #body="{ data }">
                        <InputMask
                            v-model="data.onlyHoraAgendamento"
                            mask="99:99"
                            @change="getHorariosDisponiveis"
                            :disabled="isDisabled || appointmentCompleted"
                            :class="{ 'p-invalid': submitted && !data.onlyHoraAgendamento }"
                        />
                        <small class="p-error" v-if="submitted && !data.onlyHoraAgendamento">Campo obrigatório.</small>
                    </template>
                </Column>
                <Column field="dataUltimoExame" header="Data último exame" style="min-width: 8rem">
                    <template #body="{ data }">
                        <Calendar :manualInput="true" :disabled="true" v-model="data.dataUltimoExame" mask="99/99/9999" />
                    </template>
                </Column>
                <Column bodyClass="text-right" header="Incluir">
                    <template #body="{ data }">
                        <i v-if="data.aso" class="pi pi-check" style="color: green" />
                        <i v-else class="pi pi-times" style="color: red" />
                    </template>
                </Column>
                <Column bodyClass="text-right" header="Cobrar">
                    <template #body="{ data }">
                        <i v-if="data.cobrarExame" class="pi pi-check" style="color: green" />
                        <i v-else class="pi pi-times" style="color: red" />
                    </template>
                </Column>
                <Column bodyClass="text-right" header="Particular">
                    <template #body="{ data }">
                        <i v-if="data.exameParticular" class="pi pi-check" style="color: green" />
                        <i v-else class="pi pi-times" style="color: red" />
                    </template>
                </Column>
                <Column :style="{ padding: '1rem 0.5rem' }">
                    <template #body="slotProps">
                        <Button
                            style="color: #6c757d"
                            type="button"
                            icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-text"
                            @click="toggleExames($event, slotProps)"
                            aria-haspopup="true"
                            aria-controls="exames_menu"
                        />
                    </template>
                </Column>
            </DataTable>
            <Menu id="exames_menu" ref="menuExames" :model="menuItemsExames" :popup="true" />
            <DadosPrestadorDialog
                v-if="objPrestador"
                v-bind:objPrestador="objPrestador"
                v-model:visible="openClose"
                @openCloseDadosPrestador="openCloseDadosPrestador"
            />
            <AppDescricaoDialog
                v-if="showDescricaoDialog"
                v-model:visible="showDescricaoDialog"
                title="Editar recomendações"
                @onConfirm="onClickConfirmDescricaoDialog"
                @onClose="onClickCloseDescricaoDialog"
                :defaultDescription="actionRecord.recommendation"
                :descricaoObrigatoria="false"
            >
            </AppDescricaoDialog>
            <Dialog v-model:visible="removeProcedimentoDialog" :style="{ width: '500px' }" header="Confirmar" :modal="true">
                <div class="flex align-items-center justify-content-center">
                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                    <span v-if="procedimento"
                        >Tem certeza que deseja excluir <b>{{ procedimento.procedimentos.name }}</b
                        >?</span
                    >
                </div>
                <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="removeProcedimentoDialog = false" />
                    <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="removeProcedimento" />
                </template>
            </Dialog>
            <Dialog v-model:visible="addNovoProcedimentoDialog" :style="{ width: '500px' }" header="Adicionar novo exame">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12">
                        <label for="exame">Exame</label>
                        <DropdownExame id="exame" v-model="novoAgendamentoProcedimento.procedimentos" @change="onChangeProcedimento" />
                    </div>
                    <div class="field col-12">
                        <label for="recommendation">Recomendações</label>
                        <Textarea id="recommendation" class="w-full" v-model="novoAgendamentoProcedimento.recommendation" />
                    </div>
                    <div class="field col-4">
                        <label for="aso">Incluir pedido de exame</label>
                        <div class="field-checkbox">
                            <Checkbox
                                id="aso"
                                v-model="novoAgendamentoProcedimento.aso"
                                :binary="true"
                                :disabled="isDisabled || appointmentCompleted"
                            />
                            <label for="aso">
                                {{ novoAgendamentoProcedimento.aso ? 'Sim' : 'Não' }}
                            </label>
                        </div>
                    </div>
                    <div class="field col-3">
                        <label for="exameParticular">Exame particular</label>
                        <div class="field-checkbox">
                            <Checkbox
                                id="exameParticular"
                                v-model="novoAgendamentoProcedimento.exameParticular"
                                :binary="true"
                                :disabled="isDisabled || appointmentCompleted"
                            />
                            <label for="aso">
                                {{ novoAgendamentoProcedimento.exameParticular ? 'Sim' : 'Não' }}
                            </label>
                        </div>
                    </div>
                    <div class="field col-5">
                        <label for="cobrarExame">Realizar cobrança do exame</label>
                        <div class="field-checkbox">
                            <Checkbox
                                id="aso"
                                v-model="novoAgendamentoProcedimento.cobrarExame"
                                :binary="true"
                                :disabled="!!novoAgendamentoProcedimento.exameParticular"
                            />
                            <label for="aso">
                                {{ novoAgendamentoProcedimento.cobrarExame ? 'Sim' : 'Não' }}
                            </label>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="addNovoProcedimentoDialog = false" />
                    <Button label="Adicionar" icon="pi pi-plus" class="p-button-outlined p-button-success" @click="novoProcedimento" />
                </template>
            </Dialog>
        </div>
    </div>
</template>
<script>
import MixinAgendamentoStatusValidator from '../../../mixins/MixinAgendamentoStatusValidator';
import DropdownPrestadorCliente from '../../../../prestadores/components/DropdownPrestadorCliente.vue';
import DadosPrestadorDialog from '../../../../painel-agendamento/components/DadosPrestadorDialog.vue';
import DropdownExame from '../../../../exames/components/DropdownExame.vue';
import { getDay, getHours, getMinutes } from 'date-fns';
import moment from 'moment-timezone';
export default {
    components: {
        DropdownPrestadorCliente,
        DadosPrestadorDialog,
        DropdownExame
    },
    props: {
        agendamento: {
            type: Object,
            required: true
        },
        submitted: {
            type: Boolean,
            required: true
        },
        isDisabled: {
            type: Boolean,
            required: true
        },
        appointmentCompleted: {
            type: Boolean,
            required: true
        }
    },
    mixins: [MixinAgendamentoStatusValidator],
    computed: {
        agendamentoLocal: {
            get: function () {
                return this.agendamento || {};
            },
            set: function (value) {
                this.$emit('changeAgendamento', value);
            }
        }
    },
    model: {
        props: 'agendamento',
        event: 'changeAgendamento'
    },
    data() {
        return {
            hasExameEmDia: [],
            nomesExamesEmDia: [],
            objPrestador: null,
            openClose: false,
            dadosExames: null,
            procedimento: null,
            removeProcedimentoDialog: false,
            actionRecord: null,
            showDescricaoDialog: false,
            novoAgendamentoProcedimento: null,
            addNovoProcedimentoDialog: false,
            menuItemsExames: [
                {
                    label: 'Dados do prestador',
                    icon: 'pi pi-book',
                    disabled: () => this.isStatusAgendado(this.agendamentoLocal),
                    command: () => {
                        this.openCloseDadosPrestador(this.dadosExames.data.prestador);
                    }
                },
                {
                    label: 'Recomendações',
                    icon: 'pi pi-tag',
                    disabled: () => this.isStatusAgendado(this.agendamentoLocal) || this.isStatusConcluido(this.agendamentoLocal),
                    command: () => {
                        this.onClickOpenRecommendationDialog(this.dadosExames.data);
                    }
                },
                {
                    label: 'Remover',
                    icon: 'pi pi-trash',
                    disabled: () => this.isStatusAgendado(this.agendamentoLocal) || this.isStatusConcluido(this.agendamentoLocal),
                    command: () => {
                        this.confirmRemoveProcedimento(this.dadosExames);
                    }
                }
            ]
        };
    },
    mounted() {
        this.hasExameEmDia = this.agendamentoLocal.procedimentos.filter((p) => p.examesEmDia === true);
        this.nomesExamesEmDia = this.hasExameEmDia.map((e) => ' ' + e.procedimentos.name).toString();
        this.loadData();
    },
    methods: {
        loadData() {
            this.agendamentoLocal.procedimentos.forEach((p) => {
                const dataAgendamentoIniciada = new Date(p?.dataAgendamento);
                const horaCompletaString = dataAgendamentoIniciada.toTimeString();
                const [horas, minutos] = horaCompletaString?.split(':') || [];
                if (horas && minutos) {
                    p.onlyHoraAgendamento = horas + ':' + minutos;
                }
                p.onlyDataAgendamento = p?.dataAgendamento ? moment.tz(p?.dataAgendamento, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate() : null;
                p.dataAgendamento = p.onlyDataAgendamento; 
            });
        },
        async getHorariosDisponiveis(obj) {
            if (obj.onlyHoraAgendamento && obj.onlyDataAgendamento) {
                const [hora, minuto] = obj.onlyHoraAgendamento.split(':');
                const dataHoraCompleta = moment.tz(obj.onlyDataAgendamento, 'America/Sao_Paulo').hour(hora).minute(minuto).toDate();
                obj.dataAgendamento = dataHoraCompleta;
            } else {
                return;
            }

            this.$toast.removeAllGroups();
            if (!obj.dataAgendamento) return;
            this.naoDisponivel = true;

            if (!obj?.prestador) {
                obj.dataAgendamento = null;
                this.$toast.add({ severity: 'warn', summary: 'Atenção!', detail: 'Antes de informar a data, selecione o prestador.', life: 3000 });
                return obj;
            }

            const diaSemana = this.$primevue.config.locale.dayNamesShort?.[getDay(obj.dataAgendamento)]?.toUpperCase();
            const horas = getHours(obj.dataAgendamento);
            const minutos = getMinutes(obj.dataAgendamento);
            const horaMarcada = new Date().setHours(parseInt(horas), parseInt(minutos), 1, 0);
            const { data } = await this.$servicePrestadores.findById(obj.prestador.id);

            const horariosAtendidos = data.openingHours.filter((item) => item.weekDay === diaSemana);

            if (!horariosAtendidos.length) {
                this.$toast.add({ severity: 'warn', summary: 'Atenção!', detail: 'Prestador não atende no dia da semana selecionado.', life: 3000 });
                obj.dataAgendamento = null;
                return obj;
            }

            horariosAtendidos.forEach((item) => {
                const [horaIniManha, minIniManha] = item.startHourAm.split(':');
                const [horaFinManha, minFinManha] = item.endHourAm.split(':');
                const [horaIniTarde, minIniTarde] = item.startHourPm.split(':');
                const [horaFinTarde, minFinTarde] = item.endHourPm.split(':');

                const inicioManha = new Date().setHours(parseInt(horaIniManha), parseInt(minIniManha), 1, 0);
                const finalManha = new Date().setHours(parseInt(horaFinManha), parseInt(minFinManha), 1, 0);
                const inicioTarde = new Date().setHours(parseInt(horaIniTarde), parseInt(minIniTarde), 1, 0);
                const finalTarde = new Date().setHours(parseInt(horaFinTarde), parseInt(minFinTarde), 1, 0);

                if (item.atendimentoMatutino && horaIniManha != '00' && horaFinManha != '00') {
                    const isDisponivelManha = horaMarcada >= inicioManha && horaMarcada <= finalManha;
                    if (isDisponivelManha) {
                        this.naoDisponivel = false;
                        return;
                    }
                }

                if (item.atendimentoVespertino && horaIniTarde != '00' && horaFinTarde != '00') {
                    const isDisponivelTarde = horaMarcada >= inicioTarde && horaMarcada <= finalTarde;
                    if (isDisponivelTarde) {
                        this.naoDisponivel = false;
                        return false;
                    }
                }
                this.$toast.add({ severity: 'warn', summary: 'Atenção!', detail: 'Prestador não atende na hora selecionada.', life: 3000 });
                return;
            });
        },
        openCloseDadosPrestador(obj) {
            this.openClose = !this.openClose;
            this.objPrestador = this.openClose ? obj : null;
        },
        toggleExames($event, record) {
            this.dadosExames = record;
            this.$refs.menuExames.toggle($event);
        },
        onClickOpenRecommendationDialog(record) {
            this.actionRecord = record;
            this.showDescricaoDialog = true;
        },
        onClickConfirmDescricaoDialog(descricao) {
            this.actionRecord.recommendation = descricao;
            this.showDescricaoDialog = false;
        },
        onClickCloseDescricaoDialog() {
            this.showDescricaoDialog = false;
        },
        confirmRemoveProcedimento(procedimento) {
            this.procedimento = procedimento.data;
            this.removeProcedimentoDialog = true;
        },
        removeProcedimento() {
            this.agendamentoLocal.procedimentos = this.agendamentoLocal.procedimentos.filter(
                (procedimento) => procedimento.id !== this.procedimento.id
            );
            this.removeProcedimentoDialog = false;
            this.procedimento = {};
            this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'Procedimento removido com sucesso.', life: 3000 });
        },
        openDialogNovoProcedimento() {
            this.addNovoProcedimentoDialog = true;
            this.novoAgendamentoProcedimento = {
                procedimentos: null,
                recommendation: null
            };
        },
        novoProcedimento() {
            if (!this.novoAgendamentoProcedimento.procedimentos) {
                this.$toast.add({ severity: 'error', summary: 'Exame obrigatório', life: 3000 });
                return;
            }
            this.novoAgendamentoProcedimento.agendamento = { id: this.agendamentoLocal.id };
            this.novoAgendamentoProcedimento.prestador = null;
            this.novoAgendamentoProcedimento.aso = !!this.novoAgendamentoProcedimento.aso;
            this.novoAgendamentoProcedimento.cobrarExame = !!this.novoAgendamentoProcedimento.cobrarExame;
            this.agendamentoLocal.procedimentos.push(this.novoAgendamentoProcedimento);
            this.addNovoProcedimentoDialog = false;
        }
    }
};
</script>
