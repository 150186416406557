<template>
        <Dialog class="p-fluid" :style="{ width: '450px' }" header="Confirmar" :draggable="false" :modal="true">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="agendamento">Tem certeza que deseja reagendar este agendamento?</span>
            </div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                <Button
                    label="Confirmar"
                    icon="pi pi-check"
                    class="p-button-outlined p-button-danger"
                    @click="confirmReschedule"
                    :loading="loadingReagendar"
                />
            </template>
        </Dialog>
</template>
<script>
import AreaMedicaService from '../../../services/AreaMedicaService';
import StatusAgendamento from '../../../enums/StatusAgendamento';
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loadingReagendar: false,
            enumStatusAgendamento: StatusAgendamento
        }
    },
    mounted() {
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
    },
    emits: ['onClose'],
    methods: {
        async confirmReschedule() {
            try {
                this.loadingReagendar = true;
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Reagendado.',
                    agendamento: this.agendamento,
                    status: { id: this.enumStatusAgendamento.REAGENDADO }
                }

                await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);
                this.loadingReagendar = false; 
                this.$toast.add({ severity: 'success', summary: 'Agendamento reagendado com sucesso!', life: 3000 });
                this.$emit('onClose');
            } catch(err) {
                this.loadingReagendar = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err?.response?.data?.message, life: 3000 });
            }
        },
        closeDialog() {
            this.$emit('onClose');
        }
    }
};
</script>
