<template>
    <div> componente de telemedicina </div>
</template>
<script>
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        },
    },
    model: {
        prop: 'agendamento',
        event: 'changeAgendamento'
    },
    data() {
        return {
            agendamentoLocal: {}
        }
    },
    mouted() {
        this.agendamentoLocal = this.agendamento;
    }
};
</script>
