<template>
    <Dialog header="Agendamento" :style="{ width: '90vw' }" :modal="true">
        <ProgressBar v-if="loadingAgendamento" mode="indeterminate" style="height: .3em"/>
        <TabViewAtendimento v-if="agendamento" :agendamento="agendamento" />
    </Dialog>
</template>
<script>
import BaseService from '../../../services/BaseService';
import TabViewAtendimento from './TabViewAtendimento.vue';
export default {
    components: {
        TabViewAtendimento
    },
    props: {
        idAgendamento: {
            type: Number,
            required: true
        }
    },
    computed: {
        podeIniciar() {
            return !(!this.agendamento || this.agendamentoIniciado);
        },
    },
    data() {
        return {
            record: null,
            agendamento: null,
            loadingAgendamento: false,
            agendamentoIniciado: false
        };
    },
    mounted() {
        this.$serviceAgendamento = new BaseService('/agendamentos');
        this.load();
    },
    methods: {
        async load() {
            this.loadingAgendamento = true;
            const { data } = await this.$serviceAgendamento.findById(this.idAgendamento);
            this.agendamento = data;
            this.agendamento.asoDataEmissao = data.asoDataEmissao ? new Date(data.asoDataEmissao) : null;
            this.loadingAgendamento = false;
        }
    }
};
</script>
