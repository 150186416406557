<template>
    <div class="grid">
        <div class="col-12 md:col-4">
            <div class="flex flex-row mb-2">
                <label class="margin-bottom:30px">Nome do Solicitante</label>
            </div>
            <div class="field">
                <InputText class="w-20rem" type="text" :disabled="isDisabled || appointmentCompleted" v-model="agendamentoLocal.nomeSolicitante" />
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="flex flex-row mb-2">
                <label>E-mail do Solicitante</label>
            </div>
            <div class="field">
                <InputText class="w-20rem" type="text" :disabled="isDisabled || appointmentCompleted" v-model="agendamentoLocal.emailSolicitante" />
            </div>
        </div>
        <div class="col-12 md:col-4">
            <div class="flex flex-row mb-2">
                <label>Telefone do Solicitante</label>
            </div>
            <div class="field">
                <InputMask
                    id="telefoneSolicitante"
                    v-model="agendamentoLocal.telefoneSolicitante"
                    type="text"
                    class="w-20rem"
                    mask="(99) 99999-9999"
                    :disabled="isDisabled || appointmentCompleted"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        },
        isDisabled: {
            type: Boolean,
            required: true
        },
        appointmentCompleted: {
            type: Boolean,
            required: true
        }
    },
    mode: {
        props: 'agendamento',
        events: 'changeAgendamento'
    },
    computed: {
        agendamentoLocal: {
            get: function () {
                return this.agendamento || {};
            },
            set: function (value) {
                this.$emit('changeAgendamento', value);
            }
        }
    }
};
</script>
