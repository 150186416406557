<template>
    <AppPanel subtitle="Listagem de agendamentos">
        <template #content>
            <AppPaginatedGrid
                :showActionItems="false"
                ref="grid"
                :service="service"
                :expander="false"
                subtitle="Agendamento e atendimento"
                nomeTelaDoManual="agendamento-atendimento-list"
            >
                <template #columns>
                    <Column field="user.id" header="Usuário" :sortable="true">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.user.urlFotoPerfil" class="user-photo-template">
                                <img
                                    :src="slotProps.data.user.urlFotoPerfil"
                                    class="user-photo"
                                    height="100"
                                    preview
                                    v-tooltip.top="slotProps.data.user.name"
                                />
                            </div>
                            <Avatar
                                v-if="!slotProps.data.user.urlFotoPerfil"
                                :label="
                                    slotProps.data.user.name?.split(' ')?.[1]?.[0]
                                        ? slotProps.data.user.name[0] + slotProps.data.user.name?.split(' ')?.[1]?.[0]
                                        : slotProps.data.user.name[0]
                                "
                                placeholder="Top"
                                v-tooltip.top="slotProps.data.user.name"
                                size="large"
                                style="background-color: #2196f3; color: #ffffff"
                                shape="circle"
                            />
                        </template>
                    </Column>
                    <Column field="funcionario.name" header="Funcionário" :sortable="true" sortField="funcionario.name"></Column>
                    <Column field="cliente.name" header="Cliente" :sortable="true" sortField="cliente.name"></Column>
                    <Column field="dataSugestaoInicial" header="Data Sugestão Inicial" :sortable="true" sortField="dataSugestaoInicial">
                        <template #body="slotProps">
                            {{ $filters.formatDateOnly(slotProps.data.dataSugestaoInicial) }}
                        </template>
                    </Column>
                    <Column field="dataSugestaoFinal" header="Data Sugestão Final" :sortable="true" sortField="dataSugestaoFinal">
                        <template #body="slotProps">
                            {{ $filters.formatDateOnly(slotProps.data.dataSugestaoFinal) }}
                        </template>
                    </Column>
                    <Column field="tipoExame.descricao" header="Tipo Exame" :sortable="true" sortField="tipoExame.descricao"></Column>
                    <Column field="status.descricao" header="Status" sortable>
                        <template #body="{ data }">
                            <div v-if="data.status.id == this.enumStatusAgendamento.PENDENCIA_CLIENTE">
                                <Tag
                                    class="mr-2"
                                    :style="tagStatusStyleButton(data)"
                                    :value="data.nomeSolicitante ? 'Pendência ' + data.nomeSolicitante : data.status.descricao"
                                    v-tooltip="'Clique para visualizar e resolver pendências'"
                                    @click="onClickExibirPendencias(data)"
                                >
                                </Tag>
                            </div>
                            <div v-if="data.status.id == this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA">
                                <Tag
                                    class="mr-2"
                                    :style="tagStatusStyleButton(data)"
                                    value="Aguardando confirmação de agenda"
                                    v-tooltip="'Clique aqui para reservar horários'"
                                    @click="onClickMenuReservaHorarios(data)"
                                >
                                </Tag>
                            </div>
                            <div v-if="data.status.id == this.enumStatusAgendamento.DEPOSITO_ANTECIPADO">
                                <Tag
                                    class="mr-2"
                                    :style="tagStatusStyleButton(data)"
                                    :value="data.status.descricao"
                                    v-tooltip="'Aguardando depósito antecipado para o prestador'"
                                >
                                </Tag>
                            </div>

                            <div
                                v-if="
                                    data.status.id == this.enumStatusAgendamento.DESISTENCIA_DE_VAGA ||
                                    data.status.id == this.enumStatusAgendamento.CANCELADO ||
                                    data.status.id == this.enumStatusAgendamento.PENDENCIA_PRESTADOR
                                "
                            >
                                <Tag
                                    class="mr-2"
                                    :style="tagStatusStyleButton(data)"
                                    :value="data.status.descricao"
                                    v-tooltip="'Clique para visualizar motivo'"
                                    @click="onClickExibirMotivo(data)"
                                >
                                </Tag>
                            </div>
                            <div
                                v-if="
                                    data.status.id != this.enumStatusAgendamento.PENDENCIA_CLIENTE &&
                                    data.status.id != this.enumStatusAgendamento.DESISTENCIA_DE_VAGA &&
                                    data.status.id != this.enumStatusAgendamento.CANCELADO &&
                                    data.status.id != this.enumStatusAgendamento.PENDENCIA_PRESTADOR &&
                                    data.status.id != this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA &&
                                    data.status.id != this.enumStatusAgendamento.DEPOSITO_ANTECIPADO
                                "
                            >
                                <Tag class="mr-2" :style="tagStatusStyle(data)" :value="data.status.descricao"> </Tag>
                            </div>
                        </template>
                    </Column>
                    <!-- <Column header="eSocial" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="{ data }">
                            <div v-if="data.eventos[0]" v-tooltip.left="{ value: data.eventos[0].esocialDescricao || '' }">
                                <i
                                    :style="{ color: EsocialEventoSituacaoEnumColor.get(data.eventos[0].situacao), marginTop: '4px' }"
                                    class="pi pi-circle-fill ml-1"
                                ></i>
                            </div>
                        </template>
                    </Column> -->
                    <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="slotProps">
                            <Button
                                v-if="this.isStatusEmAberto(slotProps.data)"
                                :loading="loadingIniciarAgendamento"
                                @click="startAppointment(slotProps.data)"
                                class="p-button p-button-outlined mr-2"
                                v-tooltip.bottom="'Iniciar agendamento'"
                                type="button"
                                icon="pi pi-play"
                            ></Button>
                        </template>
                    </Column>
                    <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="slotProps">
                            <Button
                                style="color: #6c757d"
                                type="button"
                                class="p-button-rounded p-button-text"
                                icon="pi pi-ellipsis-v"
                                @click="toggleMenuAgendamento($event, slotProps.data)"
                            ></Button>
                        </template>
                    </Column>
                </template>
            </AppPaginatedGrid>
            <MenuAgendamentoAtendimento v-if="agendamentoSelecionado" ref="menuAgendamentoAtendimento" :agendamento="agendamentoSelecionado" />
            <DialogIniciarAgendamentos
                v-if="showIniciarAgendamentosDialog"
                v-model:visible="showIniciarAgendamentosDialog"
                v-model:agendamentoIds="agendamentosMultiplosParaIniciar"
                @onClose="onClickCloseIniciarAgendamentos"
                @onConfirm="onClickConfirmIniciarAgendamentos"
                :loadingMultiplosAgendamentos="loadingMultiplosAgendamentos"
            />
            <DialogPainelMedico v-if="displayModal" v-model:visible="displayModal" :idAgendamento="agendamentoId" />
            <Dialog
                v-model:visible="containsObservationDialog"
                class="p-fluid"
                :style="{ width: '450px' }"
                header="Observações e Particularidades"
                :draggable="false"
                :closable="false"
                :modal="true"
            >
                <Divider align="center">
                    <span class="p-tag">Observações</span>
                </Divider>
                <span>
                    {{ agendamento.observacao ? agendamento.observacao : 'Nenhuma Observação atribuída' }}
                </span>
                <Divider align="center">
                    <span class="p-tag">Particularidades</span>
                </Divider>
                <span>{{ agendamento.cliente.particularidades ? agendamento.cliente.particularidades : 'Nenhuma Particularidade atribuída' }}</span>
                <template #footer>
                    <Button label="Fechar" icon="pi pi-times" class="p-button-outlined p-button-primary" @click="containsObservationDialog = false" />
                </template>
            </Dialog>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import StatusAgendamento from '../../enums/StatusAgendamento';
import { getClientBase } from '@/services/http';
import DialogPainelMedico from './components/DialogPainelMedico.vue';
import MixinAgendamentoStatusValidator from './mixins/MixinAgendamentoStatusValidator';
import { getClientAreaMedica } from '../../services/http';
import DialogIniciarAgendamentos from '../painel-agendamento/components/DialogIniciarAgendamentos.vue';
import MenuAgendamentoAtendimento from './components/MenuAgendamentoAtendimento.vue';
import { EsocialEventoSituacaoEnumColor } from '../esocial/eventos/enums/EsocialEventoSituacaoEnum';

export default {
    components: {
        DialogPainelMedico,
        DialogIniciarAgendamentos,
        MenuAgendamentoAtendimento
    },
    mixins: [MixinAgendamentoStatusValidator],
    data() {
        return {
            service: null,
            respostasTelemedicina: [],
            displayModal: false,
            enumStatusAgendamento: StatusAgendamento,
            questionarios: [],
            agendamento: null,
            agendamentoSelecionado: null,
            agendamentoId: null,
            containsObservationDialog: false,
            showIniciarAgendamentosDialog: false,
            agendamentosMultiplosParaIniciar: [],
            loadingIniciarAgendamento: false,
            userId: null,
            loadingMultiplosAgendamentos: false,
            customerId: null,
            companyId: null,
            linkTelemedicina: null,
            hashTelemedicina: null,
            loading: true,
            EsocialEventoSituacaoEnumColor
        };
    },
    created() {
        this.service = new BaseService('/agendamentos/telemedicina');
    },
    methods: {
        async getRespostasComCB() {
            return this.questionarios
                .filter((p) => p.tipo === 'CB')
                .map((questionario) => {
                    return {
                        id: questionario.id,
                        resposta: JSON.stringify(questionario.resposta),
                        agendamentoId: this.agendamentoId,
                        customerId: this.customerId,
                        userId: this.userId,
                        questionarioTelemedicinaId: questionario.id,
                        companyId: this.companyId
                    };
                });
        },
        async toggleMenuAgendamento($event, record) {
            await this.setAgendamentoSelecionado(record);
            this.$refs.menuAgendamentoAtendimento.toggle($event);
        },
        setAgendamentoSelecionado(record) {
            this.agendamentoSelecionado = record;
        },
        async startAppointment(record) {
            this.loadingIniciarAgendamento = true;
            try {
                this.agendamento = record;
                this.agendamentoId = record.id;
                const path = `agendamentos/multiplos-agendamentos-mesmo-cpf/${record.funcionario.cpf}`;
                const serviceMultiplosAgendamentos = new BaseService(path);
                const responseAgendamentosComMesmoCpf = await serviceMultiplosAgendamentos.findAll({});

                if (responseAgendamentosComMesmoCpf?.data?.length > 1) {
                    this.loadingIniciarAgendamento = false;
                    this.agendamentosMultiplosParaIniciar = responseAgendamentosComMesmoCpf.data.map((p) => p.id);
                    this.showIniciarAgendamentosDialog = true;
                    return;
                }

                const obs = record?.observacao?.trim();
                const hasObsOrParticularidades = !!obs || !!record.cliente.particularidades;
                this.containsObservationDialog = hasObsOrParticularidades;
                this.openDialog = hasObsOrParticularidades;

                const agendamentosIniciados = await this.iniciarAgendamentos([record.id]);

                agendamentosIniciados?.forEach((p) => {
                    (record.user = p.user), (record.userResponsavel = p.userResponsavel), (record.status = p.status);
                });

                this.appointmentStarted = true;
                this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', detail: 'Agendamento iniciado.', life: 3000 });
                this.loadingIniciarAgendamento = false;
                this.isDisabled = false;
                this.displayModal = true;
            } catch (err) {
                this.loadingIniciarAgendamento = false;
                this.appointmentStarted = false;
                this.isDisabled = true;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response?.data?.message || err.message, life: 3000 });
            }
        },
        async onClickConfirmIniciarAgendamentos(ids) {
            try {
                this.loadingMultiplosAgendamentos = true;
                const agendamentosIniciados = await this.iniciarAgendamentos(ids);

                agendamentosIniciados.forEach((p) => {
                    const agendamentoDaGrid = this.appointments?.find((agendamento) => agendamento?.id === p?.id);

                    if (agendamentoDaGrid?.id) {
                        agendamentoDaGrid.status = p.status;
                        agendamentoDaGrid.user = p.user;
                    }
                });

                this.loadingMultiplosAgendamentos = false;
                this.appointmentStarted = true;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Informações salvas com sucesso',
                    detail: 'Agendamento(s) iniciado(s).',
                    life: 3000
                });
                this.isDisabled = false;
                this.onClickCloseIniciarAgendamentos();
                this.displayModal = true;
            } catch (error) {
                this.isDisabled = true;
                this.appointmentStarted = false;
                this.loadingMultiplosAgendamentos = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao iniciar o(s) agendamento(s)!',
                    detail: error?.response?.data?.message,
                    life: 3000
                });
            }
        },
        onClickCloseIniciarAgendamentos() {
            this.showIniciarAgendamentosDialog = false;
        },
        async iniciarAgendamentos(agendamentoIds) {
            const path = `agendamentos/iniciar-agendamentos`;
            const { data } = await getClientAreaMedica().post(path, {
                agendamentoIds
            });
            return data;
        },
        async getRespostasSemCB() {
            return this.questionarios
                .filter((p) => p.tipo !== 'CB')
                .map((questionario) => {
                    return {
                        id: questionario.id,
                        resposta: questionario.resposta,
                        agendamentoId: this.agendamentoId,
                        customerId: this.customerId,
                        userId: this.userId,
                        questionarioTelemedicinaId: questionario.id,
                        companyId: this.companyId
                    };
                });
        },
        async editarQuestionario() {
            try {
                const temResponstaNaoPreenchida = this.questionarios.find((p) => !p?.resposta);
                if (temResponstaNaoPreenchida) {
                    this.$toast.add({ severity: 'warn', summary: 'Responda todas as perguntas!', life: 3000 });
                    return;
                }

                const respostas = [...(await this.getRespostasComCB()), ...(await this.getRespostasSemCB())];

                await getClientBase().put('/questionario-telemedicina-respostas', respostas);

                this.$toast.add({ severity: 'success', summary: 'Questionário salvo!', life: 3000 });
            } catch (error) {
                console.error(error);
                this.$toast.add({ severity: 'error', summary: `Erro ao salvar o questionário! ${error.response.data.message}`, life: 3000 });
            }
        },
        async salvarQuestionario() {
            try {
                const temResponstaNaoPreenchida = this.questionarios.find((p) => !p?.resposta);
                if (temResponstaNaoPreenchida) {
                    this.$toast.add({ severity: 'warn', summary: 'Responda todas as perguntas!', life: 3000 });
                    return;
                }

                const respostas = [...(await this.getRespostasComCB()), ...(await this.getRespostasSemCB())];

                await getClientBase().post('/questionario-telemedicina-respostas', respostas);
                this.$toast.add({ severity: 'success', summary: 'Questionário salvo!', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: `Erro ao salvar o questionário! ${error.response.data.message}`, life: 3000 });
            }
        },
        async load() {
            this.$refs.grid.load();
        },
        closeModal() {
            this.removeJitsiWidget();
            this.displayModal = false;
        },
        async openModal(id, userId, customerId, companyId) {
            this.displayModal = true;
            this.loading = true;
            this.agendamentoId = id;
            this.userId = userId;
            this.customerId = customerId;
            this.companyId = companyId;

            /* const responseTelemedicina = await getClientBase().get(`/agendamentos/${id}`);
        this.linkTelemedicina = `https://meet.jit.si/${responseTelemedicina.data.linkTelemedicina}`;
        this.hashTelemedicina = responseTelemedicina.data.linkTelemedicina;

        const responseQuestionario = await getClientBase().get('/questionario-telemedicina/by-company');
        this.questionarios = responseQuestionario.data;

        const responseQuestionarioTelemedicinaRespostas = await getClientBase().get(`/questionario-telemedicina-respostas/agendamento/${id}`);
        this.respostasTelemedicina = responseQuestionarioTelemedicinaRespostas.data;

        for (const respostasTelemedicina of responseQuestionarioTelemedicinaRespostas.data) {
          const index = responseQuestionario.data.findIndex((el) => el.id === respostasTelemedicina.questionarioTelemedicinaId);
          if (index >= 0) {
            if (this.questionarios[index].tipo === 'CB') {
              this.questionarios[index].resposta = JSON.parse(respostasTelemedicina.resposta);
            } else {
              this.questionarios[index].resposta = respostasTelemedicina.resposta;
            }
          }
        }

        this.loadScript('https://meet.jit.si/external_api.js', () => {
        if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded');
          this.embedJitsiWidget();
        }); */
            this.loading = false;
        },
        tagStatusStyle(data) {
            if (data?.status?.id == StatusAgendamento.PENDENCIA_PRESTADOR) {
                return {
                    'background-color': 'var(--surface-900)',
                    color: 'var(--surface-200)'
                };
            }

            return {
                'background-color': `var(--${data.status.primevueColor})`,
                color: `var(--${this.changeColorTone(data.status.primevueColor, 900)})`
            };
        },
        tagStatusStyleButton(data) {
            return {
                'background-color': `var(--${data.status.primevueColor})`,
                color: `var(--${this.changeColorTone(data.status.primevueColor, 900)})`,
                cursor: 'pointer'
            };
        },
        changeColorTone(primevueColor, newTone) {
            if (!primevueColor) return;
            const [color, tone] = primevueColor.split('-');
            return color + '-' + (newTone || tone);
        },
        loadScript(src, cb) {
            const scriptEl = document.createElement('script');
            scriptEl.src = src;
            scriptEl.async = 1;
            document.querySelector('head').appendChild(scriptEl);
            scriptEl.addEventListener('load', cb);
        },
        embedJitsiWidget() {
            const domain = 'meet.jit.si';
            const options = {
                roomName: this.hashTelemedicina,
                parentNode: this.$refs.jitsiContainer,
                configOverwrite: {
                    disableDeepLinking: true,
                    prejoinPageEnabled: false,
                    disableLocalVideoFlip: true,
                    doNotFlipLocalVideo: true,
                    hideParticipantsStats: true,
                    disableRemoteMute: true,
                    disableRemoteControl: true,
                    remoteVideoMenu: {
                        disableKick: true,
                        disableGrantModerator: true
                    },
                    subject: 'Atendimento Telemedicina'
                },
                interfaceConfigOverwrite: {
                    FILM_STRIP_MAX_HEIGHT: '0.1',
                    SHOW_CHROME_EXTENSION_BANNER: false,
                    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                    LANG_DETECTION: true,
                    VIDEO_QUALITY_LABEL_DISABLED: true,
                    CONNECTION_INDICATOR_DISABLED: true,
                    TOOLBAR_BUTTONS: ['microphone', 'camera', 'fullscreen', 'tileview', 'fullscreen']
                }
            };
            this.jitsiApi = new window.JitsiMeetExternalAPI(domain, options);
        },
        executeCommand(command, ...value) {
            this.jitsiApi.executeCommand(command, ...value);
        },
        addEventListener(event, fn) {
            this.jitsiApi.on(event, fn);
        },
        // Misc
        removeJitsiWidget() {
            if (this.jitsiApi) this.jitsiApi.dispose();
        }
    }
};
</script>

<style scoped lang="scss">
.user-photo-template {
    border: 1px solid #ced4da;
    border-style: solid;
    width: 39px;
    height: 39px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
