<template>
    <Dialog class="p-fluid" :style="{ width: '450px' }" header="Confirmar"
        :draggable="false" :closable="false" :modal="true">
        <div class="field">
            <label for="descricao">Motivo</label>
            <Textarea type="text" v-model="agendamentoLocal.motivo"
                :class="{ 'p-invalid': submitted && !agendamentoLocal?.movito }" />
            <small class="p-error" v-if="submitted && !agendamentoLocal?.movito">Campo
                obrigatório.</small>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
            <Button label="Salvar" icon="pi pi-save" class="p-button-outlined p-button-success"
                @click="savePendencies" :loading="loadingPendencia" />
        </template>
    </Dialog>
</template>
<script>
import StatusAgendamento from '../../../enums/StatusAgendamento';
import AreaMedicaService from '../../../services/AreaMedicaService';
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        }
    },
    emits: ['onClose'],
    data() {
        return {
            agendamentoLocal: null,
            enumStatusAgendamento: StatusAgendamento,
            loadingPendencia: false
        }
    },
    mounted() {
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
        this.agendamentoLocal = this.agendamento;
    },
    methods: {
        async savePendencies() {
            if (!this.agendamentoLocal.motivo) return;

            if (this.agendamentoLocal.status.id === this.enumStatusAgendamento.PENDENCIA_CLIENTE) {
                this.closeDialog();
                this.$toast.add({ severity: 'warn', summary: 'Informação', detail: 'Agendamento já está pendente', life: 3000 });
                return;
            }

            this.agendamentoLocal.status = { id: this.enumStatusAgendamento.PENDENCIA_CLIENTE };
            try {
                this.loadingPendencia = true;
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Pendente. Motivo: ' + this.agendamentoLocal.motivo,
                    agendamento: this.agendamentoLocal,
                    status: this.agendamentoLocal.status
                }

                await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso.', detail: 'Agendamento alterado para pendente.', life: 5000 });
                this.loadingPendencia = false;
                this.closeDialog();
            } catch (err) {
                this.loadingPendencia = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 3000 });
            }
        },
        closeDialog() {
            this.$emit('onClose');
        }
    }
}
</script>
