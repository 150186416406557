<template>
    <div class="grid">
        <div class="col-4">
            <div class="flex flex-row mb-2">
                <label for="asoResponsavel">Médico Coordenador</label>
            </div>
            <div class="flex flex-row">
                <DropdownProfissionalSesmt id="asoResponsavel" v-model="agendamentoLocal.asoResponsavel"
                                           :disabled="isDisabled || appointmentCompleted"
                                           :idValue="agendamentoLocal.asoResponsavelId" class="w-full"/>
            </div>
        </div>
        <div class="col-4">
            <div class="flex flex-row mb-2">
                <label for="tipo-agendamento">Tipo</label>
            </div>
            <div class="flex flex-row">
                <SelectButton
                    v-model="agendamentoLocal.tipo"
                    :disabled="isDisabled || appointmentCompleted"
                    :options="tiposAgendamento"
                    optionLabel="label"
                    optionValue="value"
                />
            </div>
        </div>
        <div class="col-4">
            <div class="field">
                <label for="parecer-aso">Parecer do ASO</label>
                <div class="field-checkbox">
                    <Checkbox id="parecer-aso" v-model="agendamentoLocal.parecerAso" :binary="true"
                              :disabled="isDisabled || appointmentCompleted"/>
                    <label for="parecer-aso">
                        {{ agendamentoLocal.parecerAso ? 'Apto' : 'Em branco' }}
                    </label>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="flex flex-row mb-2">
                <label for="medicoExaminador">Médico Examinador</label>
            </div>
            <div class="flex flex-row">
                <DropDownMedicos id="medicoExaminador" v-model="agendamentoLocal.asoExaminador"
                                 :disabled="isDisabled || appointmentCompleted"
                                 :idValue="agendamentoLocal.asoExaminadorId" class="w-full"/>
            </div>
        </div>
        <div class="col-3">
            <div class="flex flex-row mb-2">
                <label id="asoDataEmissao">Data Emissão ASO</label>
            </div>
            <div class="flex flex-row">
                <Calendar
                    id="asoDataEmissao"
                    v-model="agendamentoLocal.asoDataEmissao"
                    :disabled="true"
                    :showIcon="true"
                    mask="99/99/9999"
                    selectionMode="single"
                />
            </div>
        </div>
    </div>
</template>
<script>
import BaseService from '../../../../../services/BaseService';
import DropDownMedicos from '../../../../painel-agendamento/components/DropDownMedicos.vue';
import DropdownProfissionalSesmt from '../../../../profissionais-sesmt/components/DropdownProfissionalSesmt.vue';

export default {
    components: { DropdownProfissionalSesmt, DropDownMedicos },
    model: {
        prop: 'agendamento',
        event: 'changeAgendamento'
    },
    props: {
        agendamento: {
            type: Object,
            required: true
        },
        isDisabled: {
            type: Boolean,
            required: true
        },
        appointmentCompleted: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        agendamentoLocal: {
            get: function () {
                return this.agendamento || {};
            },
            set: function (value) {
                this.$emit('changeAgendamento', value);
            }
        }
    },
    data() {
        return {
            tiposAgendamento: [
                { label: 'Digital', value: 'DIGITAL' },
                { label: 'Manual', value: 'MANUAL' },
                { label: 'Telemedicina', value: 'TELEMEDICINA' }
            ],
            responsaveis: []
        };
    },
    mounted() {
        this.$serviceResponsaveis = new BaseService('/profissionais-sesmt');
        this.load();
    },
    methods: {
        async load() {
            const { data } = await this.$serviceResponsaveis.findAll({
                filtrosExtras: { responsavel: true }
            });
            this.responsaveis = data.items;
        }
    }
};
</script>
