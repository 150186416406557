<template>
    <div>
        <div class="col-12">
            <Divider align="center">
                <span class="p-tag-outline">Riscos</span>
            </Divider>
            <div v-if="agendamentoRiscos.length" class="p-listbox">
                <ul class="p-listbox-list">
                    <li class="p-listbox-item" v-for="record in agendamentoRiscos"
                        :key="record.id">
                        {{ record?.risco?.nome }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import AreaMedicaService from '../../../../../services/AreaMedicaService';
export default {
    props: {
        agendamento: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            agendamentoRiscos: [],
        }
    },
    mounted() {
        this.$serviceDadosAgendamentoRisco = new AreaMedicaService('/agendamento-riscos/agendamento');
        this.load();
    },
    methods: {
        async load() {
            const { data } = await this.$serviceDadosAgendamentoRisco.findById(this.agendamento.id);
            this.agendamentoRiscos = data;
        }
    }
}
</script>
