<template>
    <div class="grid">
        <Divider align="center">
            <span class="p-tag-outline">Dados do Agendamento</span>
        </Divider>
        <div class="col-12">
            <Tag
                v-if="agendamentoStatus && !agendamentoStatus.reagendado"
                class="mr-4"
                v-badge="agendamentoStatus.reagendado"
                :style="{ 'background-color': 'var(--blue-200)', color: 'var(--blue-900)' }"
                :value="'Reagendamento'"
            >
            </Tag>
            <Tag
                v-if="agendamentoLocal && agendamentoLocal.cliente.asoRetido"
                class="mr-2"
                :style="{ 'background-color': 'var(--blue-200)', color: 'var(--blue-900)' }"
                :value="'Aso retido'"
            >
            </Tag>
        </div>
        <div class="col-3">
            <div class="field">
                <label for="quemAgenda">Quem agenda</label>
                <Dropdown
                    id="quemAgenda"
                    v-model="agendamentoLocal.quemAgenda"
                    :options="agendaAvisa"
                    :disabled="isDisabled || agendamentoAgendado"
                    optionValue="value"
                    class="w-20rem"
                    optionLabel="label"
                    placeholder="Selecione quem agenda"
                >
                </Dropdown>
            </div>
        </div>
        <div class="col-3">
            <div class="field">
                <label for="quemAvisa">Quem avisa</label>
                <Dropdown
                    id="quemAvisa"
                    v-model="agendamentoLocal.quemAvisa"
                    :options="agendaAvisa"
                    :disabled="isDisabled || agendamentoAgendado"
                    optionValue="value"
                    class="w-20rem"
                    optionLabel="label"
                    placeholder="Selecione quem avisa"
                >
                </Dropdown>
            </div>
        </div>
        <div class="col-3">
            <div class="field">
                <label for="tipoExames">Tipo de exame</label>
                <Dropdown
                    id="tipoExames"
                    v-model="agendamentoLocal.tipoExame"
                    :options="tipoExames"
                    :disabled="isDisabled || agendamentoAgendado"
                    dataKey="id"
                    class="w-20rem"
                    optionLabel="descricao"
                    placeholder="Escolha o Tipo de Exame"
                />
            </div>
        </div>
        <div class="col-6 md:col-3">
            <div class="field">
                <label for="rangeDatas">Datas de sugestão</label>
                <Calendar
                    id="rangeDatas"
                    dateFormat="dd/mm/yy"
                    selectionMode="range"
                    :showIcon="true"
                    @date-select="atualizarDataSugestaoAgendamento"
                    placeholder="Selecione"
                    :disabled="isDisabled || agendamentoAgendado"
                    v-model="rangeDatas"
                />
            </div>
        </div>
        <template v-if="agendamentoLocal.tipoExame && agendamentoLocal.tipoExame.id == this.enumTipoExame.RETORNO_AO_TRABALHO">
            <div class="col-12 md:col-4">
                <div class="field">
                    <label for="tipoRetorno">Tipo de Retorno</label>
                    <Dropdown
                        id="tipoRetorno"
                        v-model="agendamentoLocal.tipoRetorno"
                        :options="tipoRetorno"
                        dataKey="id"
                        class="w-20rem"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Tipo de Retorno"
                        :disabled="isDisabled || agendamentoAgendado"
                    />
                </div>
            </div>
        </template>
        <template v-if="agendamentoLocal.tipoExame && agendamentoLocal.tipoExame.id == this.enumTipoExame.RETORNO_AO_TRABALHO">
            <div class="col-12 md:col-4">
                <div class="field">
                    <label>Data Liberação</label>
                    <Calendar
                        id="dataLiberacao"
                        v-model="agendamentoLocal.dataLiberacao"
                        dateFormat="dd/mm/yy"
                        selectionMode="single"
                        :showIcon="true"
                        :disabled="isDisabled || agendamentoAgendado"
                    />
                </div>
            </div>
        </template>
        <template v-if="agendamentoLocal.tipoExame && agendamentoLocal.tipoExame.id == this.enumTipoExame.RETORNO_AO_TRABALHO">
            <div class="col-12 md:col-12">
                <div class="field">
                    <label for="anexo">Anexo Liberação INSS/Especialista</label>
                    <FileUpload
                        v-model="agendamentoLocal.anexoLaudo"
                        chooseLabel="Escolha o arquivo"
                        :showUploadButton="false"
                        :showCancelButton="false"
                        mode="advanced"
                        name="anexoLaudo"
                        :auto="true"
                        :customUpload="true"
                        @uploader="myUploader"
                        :fileLimit="1"
                        :maxFileSize="10485760"
                        invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                        :disabled="isDisabled || agendamentoAgendado"
                    >
                        <template #empty>
                            <p>Arraste e solte o arquivo aqui.</p>
                        </template>
                    </FileUpload>
                </div>
            </div>
        </template>
        <template v-if="agendamentoLocal.tipoExame && agendamentoLocal.tipoExame.id == this.enumTipoExame.DEMISSIONAL">
            <div class="col-12 md:col-4">
                <div class="field">
                    <label>Data de Demissão</label>
                    <Calendar
                        id="dataHomologacao"
                        v-model="agendamentoLocal.dataHomologacao"
                        dateappointmentSelectedat="dd/mm/yy"
                        selectionMode="single"
                        :showIcon="true"
                        :disabled="isDisabled || agendamentoAgendado"
                    />
                </div>
            </div>
        </template>
        <template v-if="agendamentoLocal.tipoExame && agendamentoLocal.tipoExame.id == this.enumTipoExame.CONSULTA">
            <div class="col-12 md:col-6">
                <div class="field">
                    <label for="motivoConsulta">Motivo da Consulta</label>
                    <Dropdown
                        id="motivoConsulta"
                        v-model="agendamentoLocal.motivoConsulta"
                        :options="motivoConsulta"
                        dataKey="id"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione um motivo"
                        :disabled="isDisabled || agendamentoAgendado"
                    />
                </div>
            </div>
        </template>
        <template v-if="agendamentoLocal.tipoExame && agendamentoLocal.tipoExame.id == this.enumTipoExame.CONSULTA">
            <div class="col-12 md:col-6">
                <div class="field">
                    <label for="observacao">Detalhes da Consulta</label>
                    <Textarea
                        type="text"
                        v-model="agendamentoLocal.observacao"
                        :disabled="isDisabled || agendamentoAgendado"
                        autoResize
                        rows="4"
                    />
                </div>
            </div>
        </template>
        <div class="col-3">
            <div class="field">
                <label for="cidade">Cidade da consulta</label>
                <DropdownCidade id="cidade" class="w-20rem" v-model="agendamentoLocal.cidade" :disabled="isDisabled || agendamentoAgendado" />
            </div>
        </div>
        <div class="col-3">
            <div class="field">
                <label for="periodo">Período do atendimento</label>
                <Dropdown
                    id="periodo"
                    v-model="agendamentoLocal.periodo"
                    :options="periodo"
                    dataKey="id"
                    class="w-20rem"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione um periodo para o atendimento"
                    :disabled="isDisabled || agendamentoAgendado"
                />
            </div>
        </div>
        <div class="col-3">
            <div class="field">
                <label for="definir-status">Situação</label>
                <Dropdown
                    id="definir-status"
                    v-model="agendamentoLocal.situacao"
                    :options="situacoesAgendamento"
                    dataKey="id"
                    class="w-20rem"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione..."
                    :disabled="isDisabled || agendamentoAgendado"
                    :class="{ 'p-invalid': submitted && !agendamento.situacao }"
                />
                <small class="p-error" v-if="submitted && !agendamentoLocal.situacao">Campo obrigatório.</small>
            </div>
        </div>
    </div>
</template>
<script>
import SituacaoAgendamento from '../../../../../enums/SituacaoAgendamento';
import AreaMedicaService from '../../../../../services/AreaMedicaService';
import DropdownCidade from '../../../../cidades/components/DropdownCidade.vue';
import EnumTipoExame from '../../../../../enums/TipoExame';
import { nomeEmpresaUsuarioReduzido, limitarCaracteres } from '../../../../../services/auth';
import QuemAgendaAvisa from '../../../../../enums/QuemAgendaAvisa';

export default {
    components: {
        DropdownCidade
    },
    model: {
        prop: 'agendamento',
        event: 'changeAgendamento'
    },
    props: {
        agendamento: {
            type: Object,
            required: true
        },
        submitted: {
            type: Boolean,
            required: true
        },
        isDisabled: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        agendamentoLocal: {
            get: function () {
                return this.agendamento || {}
            },
            set: function (value) {
                this.$emit('changeAgendamento', value)
            }
        }
    },
    data() {
        return {
            agendamentoAgendado: false,
            agendamentoStatus: null,
            agendaAvisa: [],
            enumTipoExame: EnumTipoExame,
            page: 1,
            perPage: 10,
            filters: {
                textFilter: [],
                filtroCidade: [],
                filtroStatus: [],
                filtroResponsavel: [],
                filtroAnexos: false,
                filtroSemAnexos: false,
                filtroUsaReservaHorario: false,
                filtroAgendamentoComErro: false
            },
            tipoExames: null,
            rangeDatas: [],
            motivoConsulta: [
                { label: 'Motivo 1', value: 1 },
                { label: 'Motivo 2', value: 2 },
                { label: 'Motivo 3', value: 3 },
                { label: 'Motivo 4', value: 4 }
            ],
            periodo: [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 }
            ],
            situacoesAgendamento: [
                { label: 'Enviar confirmação', value: SituacaoAgendamento.ENVIAR_CONFIRMACAO },
                { label: 'Confirmar manualmente', value: SituacaoAgendamento.CONFIRMAR_MANUALMENTE },
                { label: 'Aguardar confirmação', value: SituacaoAgendamento.AGUARDAR_CONFIRMACAO },
                { label: 'Confirmar', value: SituacaoAgendamento.CONFIRMAR },
                { label: 'Exames em dia', value: SituacaoAgendamento.EXAMES_EM_DIA }
            ]
        };
    },
    mounted() {
        this.$serviceTipoExames = new AreaMedicaService('/tipo_exames');
        this.$serviceDadosStatus = new AreaMedicaService('/agendamento_status/agendamento');
        this.rangeDatas.push(new Date(this.agendamentoLocal.dataSugestaoInicial), new Date(this.agendamentoLocal.dataSugestaoFinal));
        this.load();
    },
    methods: {
        async load() {
            await this.loadAgendamentoStatus();
            await this.loadTipoExame();
            await this.setarQuemAgendaAvisa();
        },
        async loadAgendamentoStatus() {
            const { data } = await this.$serviceDadosStatus.findById(this.agendamentoLocal.id);
            this.agendamentoStatus = data;
        },
        async loadTipoExame() {
            const { data } = await this.$serviceTipoExames.findAll({
                limit: this.perPage,
                page: this.page,
                filter: this.filters.textFilter
            });
            this.tipoExames = data;
        },
        async setarQuemAgendaAvisa() {
            const nomeReduzidoEmpresa = await nomeEmpresaUsuarioReduzido(58);
            const nomeReduzidoUsuario = await limitarCaracteres(this.agendamentoLocal.nomeSolicitante, 58);

            this.agendaAvisa = [
                { label: nomeReduzidoUsuario, value: QuemAgendaAvisa.SOLICITANTE },
                { label: nomeReduzidoEmpresa, value: QuemAgendaAvisa.EMPRESA }
            ];
        },
        atualizarDataSugestaoAgendamento() {
            if (this.rangeDatas[0] && this.rangeDatas[1]) {
                this.agendamentoLocal.dataSugestaoInicial = this.rangeDatas[0];
                this.agendamentoLocal.dataSugestaoFinal = this.rangeDatas[1];
            }
        }
    }
};
</script>
