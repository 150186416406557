<template>
    <Dialog :breakpoints="{ '1000px': '60vw', '650px': '80vw' }" :style="{ width: '45vw' }"
        :header="agendamento.funcionario.name ? dataAtualAtualizada + ' - Timeline de ' + agendamento.funcionario.name : 'Carregando...'"
        :closable="false">
        <TabView v-model:activeIndex="active">
            <TabPanel header="Timeline Interna">
                <TimelineInterna :agendamentoId="agendamento.id" />
            </TabPanel>
            <TabPanel header="Timeline WhatsApp">
                <TimelineWhatsapp v-if="active == 1" :agendamento="agendamento" />
            </TabPanel>
        </TabView>
        <template #footer>
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-text" @click="$emit('onClose')" />
        </template>
    </Dialog>
</template>
<script>
import TimelineInterna from './components/TimelineExterna.vue';
import TimelineWhatsapp from './components/TimelineWhatsapp.vue';
export default {
    props: {
        agendamento: {
            type: Object
        },
    },
    components: {
        TimelineInterna,
        TimelineWhatsapp
    },
    data() {
        return {
            active: 0,
        };
    },
    computed: {
        dataAtualAtualizada() {
            return this.$filters.formatDate(new Date());
        }
    },
};
</script>
<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
</style>
